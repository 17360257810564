<template>
  <a-modal :title="title" width="50%" destroyOnClose :visible="visible"
    :confirm-loading="confirmLoading" @ok="handleOk" @cancel="handleCancel">
    <div>
      <!-- <a-form-model
        ref="ruleForm"
        :model="model"
        :rules="validatorRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item
          prop="deptName"
          label="部门名称"
        >
          <a-input
            id="deptName"
            v-model="model.deptName"
            placeholder="请输入部门/部门名称"
          />
        </a-form-model-item>

    <a-spin :spinning="confirmLoading">
      <a-form>
        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     label="部门名称"
                     :hidden="false"
                     hasFeedback>
          <a-input id="deptName"
                   v-model="model.deptName"
                   placeholder="请输入部门/部门名称"
                   v-decorator="['deptName', { rules: [{ required: true, message: '请输入部门/部门名称!' }] } ]" />
        </a-form-item>
        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     label="部门编码"
                     :hidden="false"
                     hasFeedback>
          <a-input id="deptNameEn"
           v-model="model.deptNameEn"
                   placeholder="请输入部门编码"
                   v-decorator="['deptNameEn', validatorRules.deptNameEn ]" />
        </a-form-item>
        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     :hidden="seen"
                     label="上级部门"
                     hasFeedback
                     v-show="isShow">
          <a-tree-select style="width:100%"
                         :dropdownStyle="{maxHeight:'200px',overflow:'auto'}"
                         :treeData="departTree"
                         v-model="model.parent"
                         placeholder="请选择上级部门"
                         :disabled="condition">
          </a-tree-select>
        </a-form-item>

        <a-form-item :labelCol="labelCol"
                     :wrapperCol="wrapperCol"
                     label="排序">
          <a-input-number v-model="model.deptOrder" v-decorator="[ 'deptOrder',{'initialValue':0}]" />
        </a-form-item>

        <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol"
          label="部门类型">
          <a-select placeholder="请选择部门类型"
            @change="handleChange"
            v-decorator="[ 'deptType', {rules: []} ]">
            <a-select-option v-for="(item,key) in deptTypeSel" :key="key" :value="item.deptType">
                {{ item.text}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="备注"
        >
          <a-textarea
            placeholder="请输入备注"
            v-model="model.remark"
            v-decorator="['remark', {}]"
          />
        </a-form-item>
      </a-form>
      </a-spin>
      </a-form-model> -->
      <ax-form :formBuilder="formBuilder" ref="formBuilder">
      </ax-form>
    </div>
  </a-modal>
</template>

<script>

import api from './api'

export default {
  name: 'SysDepartModal',
  data () {
    return {
      deptTypeSel: [],
      departTree: [],
      title: '操作',
      seen: false,
      visible: false,
      condition: true,
      disableSubmit: false,
      model: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      confirmLoading: false,
      formBuilder: this.$common.initGridFormData([
        {
          type: 'input',
          label: '部门名称',
          model: 'deptName',
          options: {
            size: 'default',
            allowClear: true,
            width: '100%',
            placeholder: '请输入部门名称'
          },
          formItem: {},
          rules: [
            {
              required: true,
              message: '不能为空',
              dynamicKey: 'required'
            }
          ],
          col: { span: 24 }
        },
        {
          type: 'input',
          label: '部门编码',
          model: 'deptNameEn',
          options: {
            size: 'default',
            allowClear: true,
            width: '100%',
            placeholder: '请输入部门编码'
          },
          formItem: {},
          rules: [
            {
              required: true,
              message: '不能为空',
              dynamicKey: 'required'
            }
          ],
          col: { span: 24 }
        },
        {
          type: 'inputNumber',
          label: '排序',
          model: 'deptOrder',
          options: {
            size: 'default',
            allowClear: true,
            width: '100%',
            placeholder: '请输入排序'
          },
          formItem: {},
          rules: [
            {
              required: true,
              message: '不能为空'
            }
          ],
          col: { span: 24 }
        },
        // {
        //   type: 'select',
        //   label: '部门类型',
        //   model: 'deptType',
        //   options: {
        //     size: 'default',
        //     allowClear: true,
        //     width: '100%',
        //     placeholder: '请选择部门类型'
        //   },
        //   formItem: {},
        //   rules: [
        //     {
        //       required: true,
        //       message: '不能为空',
        //       dynamicKey: 'required'
        //     }
        //   ],
        //   col: { span: 24 }
        // },
        {
          type: 'textarea',
          label: '备注',
          model: 'remark',
          options: {
            size: 'default',
            allowClear: true,
            width: '100%',
            placeholder: '请输入备注'
          },
          formItem: {},
          col: { span: 24 }
        }
      ])
      // validatorRules: {
      //   deptName: [{ required: true, message: '请输入部门/部门名称!' }],
      //   deptNameEn: [{ required: true, message: '请输入部门编码!' }],
      //   deptOrder: [{ required: true, message: '请输入排序!' }],
      //   deptType: { rules: [{ required: true, message: '请选择部门类型!' }] },
      //   mobile: [{ validator: this.validateMobile }]
      // }

    }
  },

  create () {
    // this.finitDictData()
  },
  methods: {
    // initDictData () {
    //   // 根据字典Code, 初始化字典数组
    //   api.ajaxGetDictItems({ dicKind: 'dept_type' }).then((res) => {
    //     if (res.status === 200) {
    //       this.deptTypeSel = res.data.map(res => { return { value: res.dicCode, label: res.dicDesc } })
    //       this.$refs.formBuilder.setFormItemProp('deptType', { options: { options: this.deptTypeSel } })
    //     }
    //   })
    // },
    handleChange (value) {
      this.model.deptType = value
    },
    loadTree () {
      this.$nextTick(() => {
        api.queryDepartTreeList().then((res) => {
          if (res.data.length > 0) {
            this.departTree = res.data
          } else {
            this.$message.warning('您没有该权限！')
          }
          this.loading = false
        })
      })
    },
    finitDictData () {
      // 根据字典Code, 初始化字典数组
      api.ajaxGetDictItems({ dicKind: 'dept_type' }).then((res) => {
        if (res.status === 200) {
          this.deptTypeSel = res.data.map(res => { return { deptType: res.dicCode, text: res.dicDesc } })
        }
      })
    },
    add (depart, type) {
      if (depart) {
        this.seen = false
      } else {
        this.seen = true
      }
      this.edit(depart, type)
    },
    async edit (record, type) {
      this.visible = true
      if (type === 'Son') {
        this.model = Object.assign({}, {})
        this.model.parent = record.deptName != null ? record.deptName.toString() : null
        this.model.parentId = record.id
        this.model.deptType = ''

        this.model.deptLevel = ''
        this.model.remark = ''
        this.model.status = ''
      } else {
        this.model = Object.assign({}, {})
        this.model.parent = record.deptName != null ? record.deptName.toString() : null
        this.model.parentId = record.id
        this.model.deptType = ''

        this.model.deptLevel = ''
        this.model.remark = ''
        this.model.status = ''
      }
    },
    close () {
      this.$emit('close')
      this.disableSubmit = false
      this.visible = false
    },
    refresh () {
      this.loading = true
      this.loadTree()
    },
    handleOk () {
      const that = this
      // 触发表单验证
      this.$refs.formBuilder.form.validateFields((err, values) => {
        if (!err) {
          // that.confirmLoading = true
          // const a = this.form.getFieldsValue()
          console.log(values, ' -------------', this.model)
          // 时间格式化
          if (this.model.parentId) {
            api.queryIdTree({ ...this.model, ...values }).then((res) => {
              this.model = {}
              that.$message.success(res.message)
              this.refresh()
            }).finally(() => {
              // this.confirmLoading = false
              this.close()
            })
          } else {
            api.add({ ...this.model, ...values, parentId: '0' }).then((res) => {
              that.$message.success(res.message)
              this.model = {}
              this.refresh()
              that.$emit('ok')
            }).finally(() => {
              // that.confirmLoading = false
              that.close()
            })
          }
          that.$emit('refresh')
        }
      })
    },
    handleCancel () {
      this.close()
    }
  }
}
</script>

<style scoped lang="less">
.ant-modal-body {
  display: none;
}
</style>
