import { post, get, deletes } from 'common/request'

export default {
  add: params => post('/dept/add', params),
  delete: params => deletes('/dept/del', params),
  edit: params => post('/dept/update', params),
  list: params => get('/dept/list', params),
  ajaxGetDictItems: (params) => get('/dict/queryItemByDictType', params),
  deleteBatch: params => post('/sys/sysDepart/deleteBatch', params),
  exportXlsUrl: params => post('sys/sysDepart/exportXls', params),
  importExcelUrl: params => post('sys/sysDepart/importExcel', params),
  queryDepartTreeList: params => get('/dept/treeList', params),
  queryIdTree: params => post('/dept/addSon', params),
  queryParentName: params => get('/sys/sysDepart/queryParentName', params),
  searchByKeywords: params => get('/dept/searchBy', params),
  // 部门可视化接口
  orgTree: params => post('/sys/sysDepart/org-tree', params),
  // 根据部门ID查询部门信息
  getDeptId: params => get(`/dept/dept-id/${params}`),
  getPId: params => get(`/dept/dept-pid/${params}`),
  // 查看当前部门下是否还存在用户
  listCheck: args => get('/user/listCheck', args),
  deleteDepart: args => deletes('/sys/sysDepart/delete', args)

}
